import React from "react"

import ErrorPage from "../components/Error/ErrorPage"
import Seo from "../components/Seo/Seo"

export default function Error() {
  return (
    <>
      <Seo title="404: Room Not Found" />
      <ErrorPage />
    </>
  )
}
