import React from "react"
import { Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

//styles
import ErrorPageStyles from "./ErrorPage.module.scss"

//components
import ErrorPageDesktop from "./ErrorPageDesktop"
import ErrorPageMobile from "./ErrorPageMobile"

export default function ErrorPage() {
  const breakpoints = useBreakpoint()
  return (
    <>
      <div className={ErrorPageStyles.container}>
        <div className={ErrorPageStyles.wrapper}>
          <h2 className={ErrorPageStyles.title}>404 Room Not Found</h2>
          <p className={ErrorPageStyles.goBackHome}>
            This room has been destroyed in a chemical disaster. <br />
            Please do not enter!
          </p>
          <Link to="/" className={ErrorPageStyles.errorBtn}>
            <button>Back to the Chemistry Room</button>
          </Link>
        </div>
        {breakpoints.sm ? <ErrorPageMobile /> : <ErrorPageDesktop />}
      </div>
    </>
  )
}
