import React from "react"

import ErrorPageStyles from "./ErrorPage.module.scss"

export default function Errorpagemobile() {
  return (
    <div className={ErrorPageStyles.mobileSvg}>
      <svg
        aria-labelledby="errorPageMobileSvg"
        role="img"
        width="40%"
        height="100%"
        viewBox="0 0 300 565"
        preserveAspectRatio="xMinYMin meet"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title id="errorPageMobileSvg">
          Error page svg with a conical flask with bubble animation
        </title>
        <path
          d="M152.004 565c52.425 0 94.923-5.316 94.923-11.874 0-6.558-42.498-11.875-94.923-11.875-52.424 0-94.922 5.317-94.922 11.875S99.58 565 152.004 565z"
          fill="#3d3d3b"
        />
        <path
          opacity=".27"
          d="M152.004 336.657c10.361 0 18.76-8.405 18.76-18.774 0-10.369-8.399-18.775-18.76-18.775-10.361 0-18.76 8.406-18.76 18.775 0 10.369 8.399 18.774 18.76 18.774z"
          fill="#D9D9D9"
        />
        <path
          d="M195.297 142.173v-14.282h-93.961v16.207c0 19.096-10.262 36.426-26.777 45.894C28.381 216.95-2.084 267.978.16 325.746c3.047 79.109 68.306 142.814 147.515 144.098C231.534 471.128 300 403.411 300 319.808c0-56.644-31.427-105.907-77.606-131.421-16.996-9.307-27.097-27.119-27.097-46.214z"
          fill="#F5F5F5"
        />
        <path
          d="M143.667 69.803c34.182 0 61.892-5.892 61.892-13.159s-27.71-13.158-61.892-13.158c-34.183 0-61.892 5.891-61.892 13.158 0 7.267 27.709 13.159 61.892 13.159z"
          fill="#C7C7C6"
        />
        <path
          d="M151.684 69.803c38.521 0 69.748-5.892 69.748-13.159s-31.227-13.158-69.748-13.158c-38.522 0-69.75 5.891-69.75 13.158 0 7.267 31.228 13.159 69.75 13.159zM151.684 132.063c38.521 0 69.748-5.891 69.748-13.158 0-7.267-31.227-13.158-69.748-13.158-38.522 0-69.75 5.891-69.75 13.158 0 7.267 31.228 13.158 69.75 13.158z"
          fill="#fff"
        />
        <path d="M221.432 56.644H81.775v62.261h139.657v-62.26z" fill="#fff" />
        <path
          d="M151.684 132.063c25.946 0 46.98-1.796 46.98-4.011 0-2.216-21.034-4.012-46.98-4.012-25.947 0-46.981 1.796-46.981 4.012 0 2.215 21.034 4.011 46.981 4.011z"
          fill="#C7C7C6"
        />
        <path
          d="M151.684 8.665c-19.883 0-35.917-1.925-47.141-4.332v50.225c11.224 2.407 27.098 4.333 47.141 4.333 20.042 0 35.916-1.926 47.14-4.333V4.333c-11.384 2.407-27.258 4.332-47.14 4.332z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M198.664 4.333C187.44 1.926 171.566 0 151.684 0c-19.883 0-35.917 1.926-47.141 4.333 11.224 2.407 27.098 4.332 47.141 4.332 20.042 0 35.756-1.925 46.98-4.332z"
          fill="url(#paint1_linear)"
        />
        <g opacity=".5">
          <path
            opacity=".5"
            d="M151.684 71.728c-19.883 0-35.917-1.925-47.141-4.332v50.225c11.224 2.407 27.098 4.333 47.141 4.333 20.042 0 35.916-1.926 47.14-4.333V67.396c-11.384 2.407-27.258 4.332-47.14 4.332z"
            fill="#C89D66"
          />
          <path
            opacity=".5"
            d="M198.664 67.396c-11.224-2.407-27.098-4.333-47.141-4.333-20.042 0-35.756 2.086-46.98 4.333 11.224 2.407 27.098 4.332 47.141 4.332 20.042 0 35.756-1.925 46.98-4.332z"
            fill="#D9B06B"
          />
        </g>
        <path
          d="M281.721 311.143c0 80.554-57.082 145.703-127.632 145.703-70.55 0-127.633-65.309-127.633-145.703"
          fill="url(#paint2_linear)"
        />
        <path
          d="M281.721 310.18c0 16.689-57.082 30.168-127.632 30.168-70.55 0-127.633-13.479-127.633-30.168 0-16.688 118.493-100.13 127.633-30.167 5.291 41.239 127.632 13.639 127.632 30.167z"
          fill="#B4DBD1"
        />
        <path
          opacity=".27"
          d="M222.394 188.708c-16.675-9.147-26.937-26.959-26.937-46.054v-14.281h-35.275v14.281c0 19.095 10.262 36.907 26.937 46.054 46.339 25.514 77.606 74.777 77.606 131.421 0 76.863-57.723 140.247-132.282 149.073 4.97.642 10.101.963 15.232.963C231.534 471.448 300 403.732 300 320.129c0-56.644-31.267-105.907-77.606-131.421zM88.614 271.363c19.71-21.832 26.367-47.959 14.868-58.356-11.5-10.397-36.799-1.128-56.508 20.704-19.71 21.832-26.367 47.958-14.868 58.356 11.499 10.397 36.799 1.127 56.508-20.704z"
          fill="#D9D9D9"
        />
        <g className={ErrorPageStyles.bubbles}>
          <path
            d="M99.562 366.247c2.084 2.407 3.207 5.616 3.207 9.146 0 7.702-5.773 13.8-12.828 13.8-2.565 0-4.97-.802-6.895-2.246 2.406 2.888 5.773 4.653 9.621 4.653 7.055 0 12.827-6.258 12.827-13.8 0-4.814-2.244-9.146-5.932-11.553z"
            fill="#ECE8D3"
          />
          <path
            d="M102.929 375.393c0-3.53-1.283-6.739-3.207-9.146-1.924-1.445-4.33-2.247-6.895-2.247C85.772 364 80 370.258 80 377.8c0 3.53 1.283 6.74 3.207 9.147 1.924 1.444 4.33 2.246 6.895 2.246 7.055 0 12.827-6.098 12.827-13.8z"
            fill="#EFEBE4"
          />
          <path
            opacity=".64"
            d="M83.848 377.961c0-4.975 3.848-9.147 8.819-9.147"
            stroke="#fff"
            strokeWidth="1.642"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M190.486 362.332c1.123 1.284 1.764 3.049 1.764 4.974 0 4.172-3.046 7.382-6.895 7.382a6.689 6.689 0 01-3.687-1.124c1.282 1.605 3.046 2.568 5.131 2.568 3.848 0 6.894-3.37 6.894-7.382 0-2.727-1.283-5.134-3.207-6.418z"
            fill="#ECE8D3"
          />
          <path
            d="M192.25 367.306c0-1.925-.641-3.691-1.764-4.974-1.122-.803-2.405-1.124-3.687-1.124-3.849 0-6.895 3.37-6.895 7.382 0 1.926.641 3.691 1.764 4.974 1.122.803 2.405 1.124 3.687 1.124 3.849 0 6.895-3.37 6.895-7.382z"
            fill="#EFEBE4"
          />
          <path
            opacity=".64"
            d="M181.988 368.59c0-2.728 2.085-4.975 4.65-4.975"
            stroke="#fff"
            strokeWidth=".886"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g>
            <path
              d="M169.321 398.437c1.764 2.246 2.886 5.134 2.886 8.183 0 6.9-5.131 12.356-11.544 12.356-2.245 0-4.49-.642-6.254-1.925 2.085 2.567 5.131 4.172 8.659 4.172 6.414 0 11.545-5.617 11.545-12.356.16-4.333-2.085-8.184-5.292-10.43z"
              fill="#ECE8D3"
            />
            <path
              d="M172.368 406.62c0-3.209-1.123-6.097-2.886-8.183-1.764-1.284-3.849-1.926-6.254-1.926-6.413 0-11.544 5.616-11.544 12.356 0 3.209 1.122 6.098 2.886 8.184 1.764 1.283 3.848 1.925 6.253 1.925 6.253 0 11.545-5.456 11.545-12.356z"
              fill="#EFEBE4"
            />
            <path
              opacity=".64"
              d="M155.371 408.867c0-4.493 3.528-8.184 7.857-8.184"
              stroke="#fff"
              strokeWidth="1.471"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g>
            <path
              d="M112.4 298.306c.802.963 1.283 2.246 1.283 3.691 0 3.048-2.245 5.456-5.131 5.456-.962 0-1.925-.321-2.726-.803.962 1.123 2.245 1.765 3.848 1.765 2.886 0 5.131-2.407 5.131-5.456 0-2.086-.962-3.69-2.405-4.653z"
              fill="#ECE8D3"
            />
            <path
              d="M113.683 301.836c0-1.444-.481-2.728-1.283-3.691-.802-.481-1.764-.802-2.726-.802-2.886 0-5.131 2.407-5.131 5.456 0 1.444.481 2.728 1.283 3.691.801.481 1.764.802 2.726.802 2.886 0 5.131-2.407 5.131-5.456z"
              fill="#EFEBE4"
            />
            <path
              opacity=".64"
              d="M106.146 302.799c0-1.926 1.604-3.691 3.528-3.691"
              stroke="#fff"
              strokeWidth=".65"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g>
            <path
              d="M152.165 307.292c1.443 1.765 2.244 4.012 2.244 6.419 0 5.455-4.008 9.788-9.139 9.788-1.764 0-3.528-.642-4.81-1.605 1.603 1.926 4.008 3.21 6.734 3.21 4.971 0 9.14-4.333 9.14-9.789 0-3.37-1.604-6.418-4.169-8.023z"
              fill="#ECE8D3"
            />
            <path
              d="M154.57 313.711c0-2.407-.802-4.814-2.245-6.419-1.443-.963-3.047-1.605-4.81-1.605-4.971 0-9.14 4.333-9.14 9.789 0 2.407.802 4.814 2.245 6.418 1.443.963 3.047 1.605 4.81 1.605 4.971 0 9.14-4.493 9.14-9.788z"
              fill="#EFEBE4"
            />
            <path
              opacity=".64"
              d="M141.101 315.476c0-3.531 2.726-6.419 6.093-6.419"
              stroke="#fff"
              strokeWidth="1.157"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g>
            <path
              d="M226.403 380.143c1.764 2.247 2.886 5.135 2.886 8.184 0 6.9-5.131 12.356-11.544 12.356-2.245 0-4.49-.642-6.254-1.926 2.085 2.568 5.131 4.172 8.659 4.172 6.413 0 11.544-5.616 11.544-12.355.161-4.333-2.084-8.184-5.291-10.431z"
              fill="#ECE8D3"
            />
            <path
              d="M229.45 388.327c0-3.209-1.123-6.098-2.887-8.184-1.763-1.283-3.848-1.925-6.253-1.925-6.414 0-11.545 5.616-11.545 12.356 0 3.209 1.123 6.097 2.887 8.183 1.763 1.284 3.848 1.926 6.253 1.926 6.253 0 11.545-5.456 11.545-12.356z"
              fill="#EFEBE4"
            />
            <path
              opacity=".64"
              d="M212.453 390.574c0-4.493 3.528-8.184 7.857-8.184"
              stroke="#fff"
              strokeWidth="1.471"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="104.549"
            y1="31.535"
            x2="198.717"
            y2="31.535"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5A168" />
            <stop offset="1" stopColor="#C8905A" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="104.549"
            y1="4.305"
            x2="198.717"
            y2="4.305"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5A168" />
            <stop offset="1" stopColor="#C8905A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="26.473"
            y1="384.091"
            x2="281.649"
            y2="384.091"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".431" stopColor="#81BFB0" />
            <stop offset="1" stopColor="#B4DCD2" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
